import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db, collection, getDocs, addDoc } from '../../config/firebase';
import Alert from '../Alert';  

const AddExpense = () => {
  const [expenseTitle, setExpenseTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [business, setBusiness] = useState('');
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [expenseItems, setExpenseItems] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [alert, setAlert] = useState({ type: '', message: '', visible: false });  // Alert state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const expenseItemsSnapshot = await getDocs(collection(db, 'expenseItems'));
      const expenseItemsData = expenseItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExpenseItems(expenseItemsData);

      const businessesSnapshot = await getDocs(collection(db, 'businesses'));
      const businessesData = businessesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBusinesses(businessesData);
    };

    fetchData();
  }, []);

  const handleAddItem = () => {
    navigate('/add-item');
  };

  const handleAddBusiness = () => {
    navigate('/add-business');
  };

  const handleSaveExpense = async () => {
    try {
      const docRef = await addDoc(collection(db, 'expenses'), {
        title: expenseTitle,
        item: selectedItem,
        date: date,
        business: business,
        amount: Number(amount),
        price: Number(price),
        paymentMethod: paymentMethod
      });
      console.log("Document written with ID: ", docRef.id);

      // Set alert for success
      setAlert({ type: 'success', message: 'Expense added successfully!', visible: true });
      
      // Optionally reset the form
      setExpenseTitle('');
      setSelectedItem('');
      setDate(new Date().toISOString().split('T')[0]);
      setBusiness('');
      setAmount('');
      setPrice('');
      setPaymentMethod('');

      // Navigate to another page or reset after a delay
      setTimeout(() => {
        setAlert({ ...alert, visible: false });
        navigate('/pos');
      }, 2000);
      
    } catch (e) {
      console.error("Error adding document: ", e);
      
      // Set alert for error
      setAlert({ type: 'error', message: 'Failed to add expense. Please try again.', visible: true });
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
      {alert.visible && <Alert type={alert.type} message={alert.message} />} {/* Display alert */}
      
      <TextField
        fullWidth
        label="Expense Title"
        variant="outlined"
        value={expenseTitle}
        onChange={(e) => setExpenseTitle(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Autocomplete
        options={expenseItems.map(item => item.name)}
        value={selectedItem}
        onChange={(event, newValue) => setSelectedItem(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Expense Item" variant="outlined" />
        )}
        sx={{ mb: 2 }}
      />
      {selectedItem && !expenseItems.some(item => item.name === selectedItem) && (
        <Button variant="contained" color="primary" fullWidth onClick={handleAddItem} sx={{ mb: 2 }}>
          Add New Item
        </Button>
      )}
      <TextField
        fullWidth
        label="Date"
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mb: 2 }}
      />
      <Autocomplete
        freeSolo
        options={businesses.map(b => b.name)}
        value={business}
        onChange={(event, newValue) => setBusiness(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Supplier" variant="outlined" />
        )}
        sx={{ mb: 2 }}
      />
      {business && !businesses.some(b => b.name === business) && (
        <Button variant="contained" color="primary" fullWidth onClick={handleAddBusiness} sx={{ mb: 2 }}>
          Add New Business
        </Button>
      )}
      <TextField
        fullWidth
        label="Amount"
        type="number"
        variant="outlined"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Price"
        type="number"
        variant="outlined"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel>Payment Method</InputLabel>
        <Select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          label="Payment Method"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="cash">Cash</MenuItem>
          <MenuItem value="credit">Credit</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" color="secondary" fullWidth onClick={handleSaveExpense}>
        Save Expense
      </Button>
    </Box>
  );
};

export default AddExpense;
