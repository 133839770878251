import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from './CartProvider';
import { FaTimes } from 'react-icons/fa';
import './Cart.css';

const CartSidebar = () => {
  const { cartItems, isCartOpen, toggleCart, removeFromCart, updateQuantity, clearCart } = useContext(CartContext);
  const navigate = useNavigate();

  const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const handleCheckout = () => {
    toggleCart(); // Close the cart
    navigate('/checkout'); // Navigate to the checkout page
  };

  return (
    <div className={`cart-sidebar ${isCartOpen ? 'open' : ''}`}>
      <button className="close-btn" onClick={toggleCart}><FaTimes /></button>
      <h2>Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div>
          {cartItems.map(item => (
            <div key={item.id} className="cart-item"> {/* Unique key prop */}
              <img src={item.images[0]} alt={item.title} />
              <div className="item-details">
                <h4>{item.title}</h4>
                <p>Price: {item.price} AED</p>
                <div className="quantity">
                  <button onClick={() => updateQuantity(item.id, item.quantity - 1)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
                </div>
              </div>
              <button className="remove-btn" onClick={() => removeFromCart(item.id)}>
                <FaTimes />
              </button>
            </div>
          ))}
          <div className="total">
            <p>Total: {total} AED</p>
          </div>
          <div className="checkout">
            <button onClick={() => clearCart()}>Clear Cart</button>
            <button onClick={handleCheckout}>Proceed to Checkout</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartSidebar;
