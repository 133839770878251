import React, { useState, useEffect, useMemo } from 'react';
import { TextField, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem, IconButton, Modal, Autocomplete } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { db, collection, getDocs, addDoc } from '../../config/firebase';
import AddCustomer from './AddCustomer';
import Alert from '../Alert'; // Import Alert component

const AddSale = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [prices, setPrices] = useState({});
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [date, setDate] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  // State for Alert
  const [alert, setAlert] = useState({ type: '', message: '' });

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'elements'));
      const productList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
    };
    fetchProducts();

    const fetchCustomers = async () => {
      const querySnapshot = await getDocs(collection(db, 'customers'));
      const customerList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customerList);
    };
    fetchCustomers();
  }, []);

  const handleProductChange = (event, newValue) => {
    if (newValue && !selectedProducts.find(p => p.id === newValue.id)) {
      setSelectedProducts([...selectedProducts, newValue]);
      setQuantities(prev => ({ ...prev, [newValue.id]: '' }));
      setPrices(prev => ({ ...prev, [newValue.id]: '' }));
    }
  };

  const handleRemoveProduct = (productId) => {
    setSelectedProducts(selectedProducts.filter(product => product.id !== productId));
    setQuantities(prev => {
      const { [productId]: removed, ...rest } = prev;
      return rest;
    });
    setPrices(prev => {
      const { [productId]: removed, ...rest } = prev;
      return rest;
    });
  };

  const handleQuantityChange = (productId, value) => {
    setQuantities(prev => ({ ...prev, [productId]: value }));
  };

  const handlePriceChange = (productId, value) => {
    setPrices(prev => ({ ...prev, [productId]: value }));
  };

  const totalPrice = useMemo(() => {
    return selectedProducts.reduce((total, product) => {
      const quantity = parseFloat(quantities[product.id]) || 0;
      const price = parseFloat(prices[product.id]) || 0;
      return total + (quantity * price);
    }, 0);
  }, [selectedProducts, quantities, prices]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const saleData = selectedProducts.map(product => ({
        productId: product.id,
        quantity: parseInt(quantities[product.id]),
        price: parseFloat(prices[product.id]),
      }));
      await addDoc(collection(db, 'sales'), {
        customer: selectedCustomer ? selectedCustomer.id : null,
        products: saleData,
        date: new Date(date),
        paymentMethod,
        totalPrice
      });

      // Reset form
      setSelectedProducts([]);
      setQuantities({});
      setPrices({});
      setDate('');
      setPaymentMethod('');
      setSelectedCustomer(null);

      // Show success alert
      setAlert({ type: 'success', message: 'Sale added successfully!' });
    } catch (error) {
      console.error('Error adding sale: ', error);
      // Show error alert
      setAlert({ type: 'error', message: 'Failed to add sale. Please try again.' });
    }
  };

  const handleOpenCustomerModal = () => setOpenCustomerModal(true);
  const handleCloseCustomerModal = () => setOpenCustomerModal(false);

  const refreshCustomers = async () => {
    const querySnapshot = await getDocs(collection(db, 'customers'));
    const customerList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCustomers(customerList);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Add Sale</Typography>

      {/* Display Alert */}
      {alert.message && <Alert type={alert.type} message={alert.message} />}

      <form onSubmit={handleSubmit}>
        <Autocomplete
          options={customers}
          getOptionLabel={(option) => option.name}
          value={selectedCustomer}
          onChange={(event, newValue) => {
            setSelectedCustomer(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Search and select customer" />}
          sx={{ mt: 2 }}
        />
        <Button onClick={handleOpenCustomerModal} variant="outlined" sx={{ mt: 1 }}>
          Add New Customer
        </Button>
        <Autocomplete
          options={products}
          getOptionLabel={(option) => option.title}
          onChange={handleProductChange}
          renderInput={(params) => <TextField {...params} label="Search and select products" />}
          sx={{ mt: 2 }}
        />
        {selectedProducts.map(product => (
          <Box key={product.id} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
            <Typography sx={{ flexGrow: 1 }}>{product.title}</Typography>
            <TextField
              label="Quantity"
              type="number"
              value={quantities[product.id] || ''}
              onChange={(e) => handleQuantityChange(product.id, e.target.value)}
              required
            />
            <TextField
              label="Price"
              type="number"
              value={prices[product.id] || ''}
              onChange={(e) => handlePriceChange(product.id, e.target.value)}
              required
            />
            <IconButton onClick={() => handleRemoveProduct(product.id)}>
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
        <TextField
          label="Date"
          type="date"
          fullWidth
          value={date}
          onChange={(e) => setDate(e.target.value)}
          margin="normal"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel>Payment Method</InputLabel>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="credit">Credit</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Total: {totalPrice.toFixed(2)} AED
        </Typography>
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Add Sale
        </Button>
      </form>

      <Modal open={openCustomerModal} onClose={handleCloseCustomerModal}>
        <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', marginTop: '10%', maxWidth: 500 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Add New Customer</Typography>
          <AddCustomer />
          <Button onClick={() => { handleCloseCustomerModal(); refreshCustomers(); }} variant="contained" color="primary" sx={{ mt: 2 }}>
            Done
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddSale;
