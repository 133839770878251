import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { db, collection, addDoc } from '../../config/firebase';

const AddBusiness = () => {
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'businesses'), { name, contact, address });
      setName('');
      setContact('');
      setAddress('');
      alert('Business added successfully!');
    } catch (error) {
      console.error('Error adding business: ', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Add Business</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          label="Contact"
          fullWidth
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          label="Address"
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Add Supplier
        </Button>
      </form>
    </Box>
  );
};

export default AddBusiness;
