import React, { useEffect, useState } from 'react';
import { db, collection, getDocs, addDoc, getDoc, doc, setDoc } from '../../config/firebase';
import { Typography, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const CalculateCash = () => {
  const [totalCash, setTotalCash] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [clearedCash, setClearedCash] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchSalesExpensesAndClearedCash = async () => {
      const salesSnapshot = await getDocs(collection(db, 'sales'));
      const salesData = salesSnapshot.docs.map(doc => doc.data());
  
      // Filter sales by date range
      const filteredSales = salesData.filter(sale => {
        const saleDate = sale.date.toDate ? sale.date.toDate() : new Date(sale.date); // Handle different formats
        return (!startDate || saleDate >= new Date(startDate)) && 
               (!endDate || saleDate <= new Date(endDate));
      });
  
      const cashSalesTotal = filteredSales.reduce((acc, sale) => acc + (sale.paymentMethod === 'cash' ? sale.totalPrice : 0), 0);
      const creditSalesTotal = filteredSales.reduce((acc, sale) => acc + (sale.paymentMethod === 'credit' ? sale.totalPrice : 0), 0);
  
      setTotalCash(cashSalesTotal);
      setTotalCredit(creditSalesTotal);
  
      const expensesSnapshot = await getDocs(collection(db, 'expenses'));
      const expensesData = expensesSnapshot.docs.map(doc => doc.data());
  
      // Filter expenses by date range
      const filteredExpenses = expensesData.filter(expense => {
        const expenseDate = expense.date.toDate ? expense.date.toDate() : new Date(expense.date); // Handle different formats
        return (!startDate || expenseDate >= new Date(startDate)) && 
               (!endDate || expenseDate <= new Date(endDate));
      });
  
      const expensesTotal = filteredExpenses.reduce((acc, expense) => acc + expense.amount, 0);
      setTotalExpenses(expensesTotal);
  
      // Fetch the last cleared cash amount
      const clearedCashDoc = await getDoc(doc(db, 'cashClear', 'latest'));
      if (clearedCashDoc.exists()) {
        setClearedCash(clearedCashDoc.data().amount);
      }
    };
  
    fetchSalesExpensesAndClearedCash();
  }, [startDate, endDate]);
  
  
  const creditCommission = totalCredit * 0.025; // 2.5% commission on credit sales
  const totalSalesBeforeVAT = totalCash + totalCredit - creditCommission;
  const vatRate = 0.05; // 5% VAT
  const totalSalesAfterVAT = totalSalesBeforeVAT / (1 + vatRate); // VAT should reduce the amount
  const vat = totalSalesBeforeVAT - totalSalesAfterVAT; // Calculate VAT based on the difference
  const currentCash = totalCash - totalExpenses - clearedCash;
  const totalNet = totalSalesAfterVAT - totalExpenses; // Net after applying VAT

  const handleClearCash = async () => {
    if (window.confirm("Are you sure you want to clear the cash in the till?")) {
      try {
        await addDoc(collection(db, 'cashClear'), {
          clearedAmount: currentCash,
          timestamp: new Date()
        });

        // Update the 'latest' document in 'cashClear' collection with the latest cleared cash amount
        await setDoc(doc(db, 'cashClear', 'latest'), {
          amount: currentCash,
          timestamp: new Date()
        });

        alert("Cash has been cleared.");
        setClearedCash(clearedCash + currentCash); // Update local state
        setTotalCash(0); // Optional: Reset total cash, might depend on your logic
      } catch (error) {
        console.error("Error clearing cash: ", error);
        alert("Failed to clear cash. Please try again.");
      }
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Cash Flow Summary</Typography>
      
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Total Sales (Cash)</TableCell>
              <TableCell align="right">{totalCash.toFixed(2)} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Sales (Credit)</TableCell>
              <TableCell align="right">{totalCredit.toFixed(2)} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Credit Card Commission (2.5%)</TableCell>
              <TableCell align="right">{creditCommission.toFixed(2)} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Sales (Before VAT)</TableCell>
              <TableCell align="right">{totalSalesBeforeVAT.toFixed(2)} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>VAT (5%)</TableCell>
              <TableCell align="right">{vat.toFixed(2)} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total Sales (After VAT)</strong></TableCell>
              <TableCell align="right"><strong>{totalSalesAfterVAT.toFixed(2)} AED</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Expenses</TableCell>
              <TableCell align="right">{totalExpenses.toFixed(2)} AED</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total Net (Sales after VAT - Expenses)</strong></TableCell>
              <TableCell align="right"><strong>{totalNet.toFixed(2)} AED</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Current Cash</strong></TableCell>
              <TableCell align="right"><strong>{currentCash.toFixed(2)} AED</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="body1" sx={{ mb: 4 }}>
        This summary shows the cash flow based on the selected date range. 
        The current cash is calculated by subtracting total expenses and cleared cash from total cash sales.
        The total net represents the overall profit, calculated by subtracting total expenses from total sales after VAT.
        A 2.5% commission is applied to credit card sales, and a 5% VAT is applied to the total sales after the credit card commission.
      </Typography>

      <Button variant="contained" color="primary" onClick={handleClearCash}>
        Clear Cash in Till
      </Button>
    </Box>
  );
};

export default CalculateCash;
