import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { db, collection, addDoc } from '../../config/firebase';

const AddCustomer = () => {
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState(''); // Added state for address

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'customers'), { name, contact, email, address }); // Include address
      setName('');
      setContact('');
      setEmail('');
      setAddress(''); // Clear address field
      alert('Customer added successfully!');
    } catch (error) {
      console.error('Error adding customer: ', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Add Customer</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          
        />
        <TextField
          label="Contact"
          fullWidth
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          
        />
        <TextField
          label="Address" // Added address input field
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          margin="normal"
          
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Add Customer
        </Button>
      </form>
    </Box>
  );
};

export default AddCustomer;
