import React, { useState, useEffect } from 'react';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import { db, storage } from '../../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './CreateElement.css';
import { useAuth } from '../contexts/AuthContext';
import Alert from '../Alert';

const CreateElement = () => {
  const { currentUser } = useAuth();
  const [element, setElement] = useState({
    title: '',
    arTitle: '',
    description: '',
    arDescription: '',
    price: '',
    calories: '',
    protein: '',
    carbs: '',
    fiber: '',
    fat: '',
    images: [],
    foodType: 'Null'  // New field for food type
  });

  const [imageFiles, setImageFiles] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);
        } else {
          console.log('No such user document!');
        }
      }
    };
    fetchUserRole();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setElement(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    setImageFiles([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userRole !== 'admin') {
      setAlert({ type: 'error', message: 'You do not have permission to perform this action' });
      return;
    }

    try {
      // Upload images to Firebase Storage
      const imageUrls = await Promise.all(
        imageFiles.map(async (file) => {
          const storageRef = ref(storage, `elements/${file.name}`);
          await uploadBytes(storageRef, file);
          return await getDownloadURL(storageRef);
        })
      );

      // Add document to Firestore
      await addDoc(collection(db, 'elements'), {
        ...element,
        images: imageUrls,
      });

      setAlert({ type: 'success', message: 'Element created successfully!' });

      // Reset form
      setElement({
        title: '',
        arTitle: '',
        description: '',
        arDescription: '',
        price: '',
        calories: '',
        protein: '',
        carbs: '',
        fiber: '',
        fat: '',
        images: [],
        foodType: 'Meat'  // Reset food type to default
      });
      setImageFiles([]);
    } catch (error) {
      console.error("Error adding document: ", error);
      setAlert({ type: 'error', message: `Error adding document: ${error.message}` });
    }
  };

  return (
    <div className="create-element-container">
      <h2 className="primary-heading">Create New Element</h2>
      {alert.message && <Alert type={alert.type} message={alert.message} />}
      <form onSubmit={handleSubmit} className="create-element-form">
        <input
          type="text"
          name="title"
          value={element.title}
          onChange={handleChange}
          placeholder="Title"
          required
        />
        <input
          type="text"
          name="arTitle"
          value={element.arTitle}
          onChange={handleChange}
          placeholder="Arabic Title"
        />
        <textarea
          name="description"
          value={element.description}
          onChange={handleChange}
          placeholder="Description"
          required
        />
        <textarea
          name="arDescription"
          value={element.arDescription}
          onChange={handleChange}
          placeholder="Arabic Description"
          required
        />
        <input
          type="number"
          name="price"
          value={element.price}
          onChange={handleChange}
          placeholder="Price"
          required
        />
        <input
          type="number"
          name="calories"
          value={element.calories}
          onChange={handleChange}
          placeholder="Calories"
        />
        <input
          type="number"
          name="protein"
          value={element.protein}
          onChange={handleChange}
          placeholder="Protein (g)"
        />
        <input
          type="number"
          name="carbs"
          value={element.carbs}
          onChange={handleChange}
          placeholder="Carbs (g)"
        />
        <input
          type="number"
          name="fiber"
          value={element.fiber}
          onChange={handleChange}
          placeholder="Fiber (g)"
        />
        <input
          type="number"
          name="fat"
          value={element.fat}
          onChange={handleChange}
          placeholder="Fat (g)"
        />
        <select
          name="foodType"
          value={element.foodType}
          onChange={handleChange}
          required
        >
          <option value="">Select Food Type</option>
          <option value="Meat">Meat</option>
          <option value="Chicken">Chicken</option>
          <option value="Fish">Fish</option>
        </select>
        <input
          type="file"
          onChange={handleImageChange}
          multiple
          accept="image/*"
        />
        <button type="submit" className="secondary-button">Create Element</button>
      </form>
    </div>
  );
};

export default CreateElement;
