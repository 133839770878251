import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import Sidebar from '../Sidebar'; // Adjust the path based on your file structure
import './Dashboard.css';

const Dashboard = () => {
  const [elementsCount, setElementsCount] = useState(0);
  const [plansCount, setPlansCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      const elementsSnapshot = await getDocs(collection(db, 'elements'));
      const plansSnapshot = await getDocs(collection(db, 'plans'));

      setElementsCount(elementsSnapshot.size);
      setPlansCount(plansSnapshot.size);
    };

    fetchCounts();
  }, []);

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard">
        <h1>Dashboard</h1>
        <div className="dashboard-cards">
          <div className="dashboard-card">
            <h2>Elements</h2>
            <p>{elementsCount}</p>
          </div>
          <div className="dashboard-card">
            <h2>Plans</h2>
            <p>{plansCount}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
