import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../config/firebase';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Container,
  Paper,
} from '@mui/material';

const EditPlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();  // get the plan ID from the route parameters
  const [planName, setPlanName] = useState('');
  const [planPrice, setPlanPrice] = useState('');
  const [totalProtein, setTotalProtein] = useState('');
  const [totalCalories, setTotalCalories] = useState('');
  const [totalCarbs, setTotalCarbs] = useState('');
  const [totalFiber, setTotalFiber] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('');
  const [numberOfMealsPerMonth, setNumberOfMealsPerMonth] = useState('');
  const [numberOfSnacksPerDay, setNumberOfSnacksPerDay] = useState('');
  const [meals, setMeals] = useState({
    Sunday: '',
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: ''
  });
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    const fetchPlan = async () => {
      const docRef = doc(db, 'plans', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const planData = docSnap.data();
        setPlanName(planData.planName);
        setPlanPrice(planData.planPrice);
        setTotalProtein(planData.totalProtein);
        setTotalCalories(planData.totalCalories);
        setTotalCarbs(planData.totalCarbs);
        setTotalFiber(planData.totalFiber);
        setNumberOfDays(planData.numberOfDays);
        setNumberOfMealsPerMonth(planData.numberOfMealsPerMonth);
        setNumberOfSnacksPerDay(planData.numberOfSnacksPerDay);
        setMeals(planData.meals);
        setImageURL(planData.imageURL);
      } else {
        console.log("No such document!");
      }
    };

    fetchPlan();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in meals) {
      setMeals({ ...meals, [name]: value });
    } else {
      switch (name) {
        case 'planName':
          setPlanName(value);
          break;
        case 'planPrice':
          setPlanPrice(value);
          break;
        case 'totalProtein':
          setTotalProtein(value);
          break;
        case 'totalCalories':
          setTotalCalories(value);
          break;
        case 'totalCarbs':
          setTotalCarbs(value);
          break;
        case 'totalFiber':
          setTotalFiber(value);
          break;
        case 'numberOfDays':
          setNumberOfDays(value);
          break;
        case 'numberOfMealsPerMonth':
          setNumberOfMealsPerMonth(value);
          break;
        case 'numberOfSnacksPerDay':
          setNumberOfSnacksPerDay(value);
          break;
        case 'image':
          setImage(e.target.files[0]);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedImageURL = imageURL;
      if (image) {
        const storage = getStorage();
        const imageRef = ref(storage, `plans/${image.name}`);
        await uploadBytes(imageRef, image);
        updatedImageURL = await getDownloadURL(imageRef);
        setImageURL(updatedImageURL);
      }

      const docRef = doc(db, 'plans', id);
      await updateDoc(docRef, {
        planName,
        planPrice,
        totalProtein,
        totalCalories,
        totalCarbs,
        totalFiber,
        numberOfDays,
        numberOfMealsPerMonth,
        numberOfSnacksPerDay,
        meals,
        imageURL: updatedImageURL
      });

      console.log('Document updated with ID: ', docRef.id);
      navigate('/plans');
    } catch (e) {
      console.error('Error updating document: ', e);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" gutterBottom>Edit Plan</Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            label="Plan Name"
            name="planName"
            value={planName}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Plan Price (AED)"
            name="planPrice"
            type="number"
            value={planPrice}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Total Protein (g)"
            name="totalProtein"
            type="number"
            value={totalProtein}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Total Calories (kcal)"
            name="totalCalories"
            type="number"
            value={totalCalories}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Total Carbs (g)"
            name="totalCarbs"
            type="number"
            value={totalCarbs}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Total Fiber (g)"
            name="totalFiber"
            type="number"
            value={totalFiber}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Number of Days"
            name="numberOfDays"
            type="number"
            value={numberOfDays}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Number of Meals per Month"
            name="numberOfMealsPerMonth"
            type="number"
            value={numberOfMealsPerMonth}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Number of Snacks per Day"
            name="numberOfSnacksPerDay"
            type="number"
            value={numberOfSnacksPerDay}
            onChange={handleInputChange}
            margin="normal"
          />
          <Button
            variant="contained"
            component="label"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          >
            Upload Plan Image
            <input
              type="file"
              name="image"
              onChange={handleInputChange}
              hidden
            />
          </Button>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>Meals</Typography>
            <Grid container spacing={2}>
              {Object.keys(meals).map((day) => (
                <Grid item xs={12} sm={6} key={day}>
                  <TextField
                    fullWidth
                    label={`${day} Meal`}
                    name={day}
                    value={meals[day]}
                    onChange={handleInputChange}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Update Plan
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default EditPlan;
