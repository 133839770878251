import React, { useContext, useState } from 'react';
import { CartContext } from '../cart/CartProvider';
import './Checkout.css';
import { FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const { cartItems, clearCart } = useContext(CartContext);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [deliveryMethod, setDeliveryMethod] = useState('pickup');
  const restaurantWhatsAppNumber = '+971556014603';

  const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const deliveryFee = deliveryMethod === 'delivery' && total < 80 ? 10 : 0;
  const finalTotal = total + deliveryFee;

  const handleConfirmOrder = () => {
    const orderDetails = cartItems.map(item => (
      `*${i18n.language === 'ar' ? item.arTitle : item.title}* x ${item.quantity} - ${item.price * item.quantity} AED`
    )).join('\n');

    const message = `${t('New Order')}:\n\n${orderDetails}\n\n${t('Subtotal')}: ${total} AED\n${t('Delivery Fee')}: ${deliveryFee} AED\n${t('Total')}: ${finalTotal} AED\n${t('Payment Method')}: ${t(paymentMethod)}\n${t('Delivery Method')}: ${t(deliveryMethod)}`;
    const whatsappUrl = `https://wa.me/${restaurantWhatsAppNumber}?text=${encodeURIComponent(message)}`;
    
    window.open(whatsappUrl, '_blank');
    clearCart();
  };

  return (
    <div 
      className="checkout-container" 
      style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
    >
      <h2>{t('Checkout')}</h2>
      <div className="checkout-section">
        <h3>{t('Payment Method')}</h3>
        <label>
          <input 
            type="radio" 
            name="payment" 
            value="cash" 
            checked={paymentMethod === 'cash'} 
            onChange={() => setPaymentMethod('cash')} 
          />
          {t('Cash on Delivery')}
        </label>
        <label>
          <input 
            type="radio" 
            name="payment" 
            value="card" 
            checked={paymentMethod === 'card'} 
            onChange={() => setPaymentMethod('card')} 
          />
          {t('Credit Card')}
        </label>
      </div>
      <div className="checkout-section">
        <h3>{t('Delivery Method')}</h3>
        <label>
          <input 
            type="radio" 
            name="delivery" 
            value="pickup" 
            checked={deliveryMethod === 'pickup'} 
            onChange={() => setDeliveryMethod('pickup')} 
          />
          {t('Pick Up')}
        </label>
        <label>
          <input 
            type="radio" 
            name="delivery" 
            value="delivery" 
            checked={deliveryMethod === 'delivery'} 
            onChange={() => setDeliveryMethod('delivery')} 
          />
          {t('Delivery')}
        </label>
        {deliveryMethod === 'delivery' && total < 80 && (
          <p className="delivery-note">{t('Orders with 80 AED or more get free delivery.')}</p>
        )}
      </div>
      <div className="checkout-summary">
        <h3>{t('Order Summary')}</h3>
        {cartItems.map(item => (
          <div key={item.id} className="checkout-summary-item">
            <span>{i18n.language === 'ar' ? item.arTitle : item.title} x {item.quantity}</span>
            <span>{item.price * item.quantity} AED</span>
          </div>
        ))}
        <div className="checkout-summary-item">
          <span>{t('Subtotal')}</span>
          <span>{total} AED</span>
        </div>
        <div className="checkout-summary-item">
          <span>{t('Delivery Fee')}</span>
          <span>{deliveryFee} AED</span>
        </div>
        <div className="checkout-summary-item checkout-total">
          <span>{t('Total')}</span>
          <span>{finalTotal} AED</span>
        </div>
      </div>
      <button className="confirm-order-btn" onClick={handleConfirmOrder}>
        <FaWhatsapp /> {t('Confirm Order')}
      </button>
    </div>
  );
};

export default Checkout;
