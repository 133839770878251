import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../Assets/logo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import PhoneIcon from '@mui/icons-material/Phone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import { useAuth } from './contexts/AuthContext';
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const { currentUser, userRole } = useAuth();
  const { i18n } = useTranslation();

  const menuOptions = [
    {
      text: i18n.language === 'ar' ? "الرئيسية" : "Home",
      icon: <HomeIcon />,
      link: "/"
    },
    {
      text: i18n.language === 'ar' ? "القائمة" : "Menu",
      icon: <RestaurantMenuIcon />,
      link: "/menu"
    },
    {
      text: i18n.language === 'ar' ? "الاشتراكات" : "Plans",
      icon: <CommentRoundedIcon />,
      link: "/plans"
    },
    {
      text: i18n.language === 'ar' ? "اتصل بنا" : "Contact",
      icon: <PhoneIcon />,
      link: "/contact"
    },
  ];

  if (currentUser && userRole === 'admin' || userRole === 'mod') {
    menuOptions.push({
      text: i18n.language === 'ar' ? "لوحة التحكم" : "Dashboard",
      icon: <DashboardIcon />,
      link: "/dashboard"
    });
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const handleImHungry = () => {
    navigate('/menu');
  };

  const handleDrawerClose = (event) => {
    if (!event.target.closest('.language-switcher')) {
      setOpenMenu(false);
    }
  };

  return (
    <nav className="navbar-container">
      <div className="nav-logo-container">
        <Link to="/">
          <img src={Logo} alt="Logo" className="nav-logo" />
        </Link>
      </div>
      <div className="navbar-links-container">
        {menuOptions.map((item) => (
          <Link to={item.link} key={item.text}>
            {item.text}
          </Link>
        ))}
        <LanguageSwitcher />
        {currentUser ? (
          <>
            <button className="primary-button" onClick={handleLogout}>
              <LogoutIcon /> {i18n.language === 'ar' ? "تسجيل الخروج" : "Logout"}
            </button>
          </>
        ) : (
          <Link to="/login">
            <button className="primary-button">
              <LoginIcon /> {i18n.language === 'ar' ? "تسجيل الدخول" : "Login"}
            </button>
          </Link>
        )}
        <button className="primary-button" onClick={handleImHungry}>
          {i18n.language === 'ar' ? "أنا جائع!" : "I am Hungry!"}
        </button>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer 
        open={openMenu} 
        onClose={handleDrawerClose} 
        anchor="right"
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component={Link} to={item.link}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
            <Divider />
            {currentUser ? (
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon><LogoutIcon /></ListItemIcon>
                  <ListItemText primary={i18n.language === 'ar' ? "تسجيل الخروج" : "Logout"} />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/login">
                  <ListItemIcon><LoginIcon /></ListItemIcon>
                  <ListItemText primary={i18n.language === 'ar' ? "تسجيل الدخول" : "Login"} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding className="language-switcher">
              <ListItemButton>
                <LanguageSwitcher inDrawer={true} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
