import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Box, Typography, Button, Card, CardContent, CardActions, Grid, Modal, IconButton } from '@mui/material';
import { WhatsApp, Delete, Edit, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Subscription = () => {
  const [plans, setPlans] = useState([]);
  const [showMeals, setShowMeals] = useState({});
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const { userRole } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPlans = async () => {
      const querySnapshot = await getDocs(collection(db, 'plans'));
      const plansData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Sort plans by planName with numbers first
      plansData.sort((a, b) => a.planName.localeCompare(b.planName, undefined, { numeric: true }));
      
      setPlans(plansData);
    };
    fetchPlans();
  }, []);

  const toggleShowMeals = (planId) => {
    setShowMeals(prevState => ({ ...prevState, [planId]: !prevState[planId] }));
  };

  const handleSubscribe = (plan) => {
    const message = t('subscriptionMessage', { planName: plan.planName, details: JSON.stringify(plan) });
    window.open(`https://api.whatsapp.com/send?phone=971556014603&text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleDelete = async (planId) => {
    try {
      await deleteDoc(doc(db, 'plans', planId));
      setPlans(plans.filter(plan => plan.id !== planId));
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };

  const handleOpen = (imageURL) => {
    setCurrentImage(imageURL);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage('');
  };

  const handleEdit = (planId) => {
    navigate(`/plans/edit/${planId}`);
  };

  return (
    <Box sx={{ bgcolor: 'background.default', color: 'text.primary', py: 8 }}>
      <Box sx={{ maxWidth: 'lg', mx: 'auto', textAlign: 'center', mb: 8 }}>
        <Typography variant="h2" component="h2" gutterBottom>
          {t('foodSubscriptionPlans')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          {t('chooseYourPlan')}
        </Typography>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan) => (
          <Grid item key={plan.id} xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                {plan.planName && (
                  <Typography variant="h5" component="div" gutterBottom>
                    {plan.planName}
                  </Typography>
                )}
                {plan.planPrice && (
                  <Typography variant="h6" color="success" gutterBottom>
                    {plan.planPrice} AED
                  </Typography>
                )}
                {plan.imageURL && (
                  <img
                    src={plan.imageURL}
                    alt={plan.planName}
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                    onClick={() => handleOpen(plan.imageURL)}
                  />
                )}
                {plan.monthly && (
                  <Typography variant="body2" color="text.secondary">
                    {t('monthlyPlan')}: {plan.monthly}
                  </Typography>
                )}
                {plan.totalProtein && (
                  <Typography variant="body2" color="text.secondary">
                    {t('totalProtein')}: {plan.totalProtein}g
                  </Typography>
                )}
                {plan.totalCalories && (
                  <Typography variant="body2" color="text.secondary">
                    {t('totalCalories')}: {plan.totalCalories} kcal
                  </Typography>
                )}
                {plan.totalCarbs && (
                  <Typography variant="body2" color="text.secondary">
                    {t('totalCarbs')}: {plan.totalCarbs}g
                  </Typography>
                )}
                {plan.totalFiber && (
                  <Typography variant="body2" color="text.secondary">
                    {t('totalFiber')}: {plan.totalFiber}g
                  </Typography>
                )}
                {plan.numberOfDays && (
                  <Typography variant="body2" color="text.secondary">
                    {t('numberOfDays')}: {plan.numberOfDays}
                  </Typography>
                )}
                {plan.numberOfMealsPerMonth && (
                  <Typography variant="body2" color="text.secondary">
                    {t('numberOfMealsPerMonth')}: {plan.numberOfMealsPerMonth}
                  </Typography>
                )}
                {plan.numberOfSnacksPerDay && (
                  <Typography variant="body2" color="text.secondary">
                    {t('numberOfSnacksPerDay')}: {plan.numberOfSnacksPerDay}
                  </Typography>
                )}
                {showMeals[plan.id] && (
                  <Box sx={{ mt: 2, textAlign: 'left' }}>
                    {Object.keys(plan.meals).map((day) => (
                      <Typography key={day} variant="body2">
                        {t(day)}: {plan.meals[day]}
                      </Typography>
                    ))}
                  </Box>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<WhatsApp />}
                  onClick={() => handleSubscribe(plan)}
                >
                  {t('subscribe')}
                </Button>
                {plan.mealsPerDay && plan.mealsPerDay.length > 0 && (
  <Button variant="outlined" color="secondary" onClick={() => toggleShowMeals(plan.id)}>
    {showMeals[plan.id] ? t('hideMealList') : t('showMealList')}
  </Button>
)}
                {userRole === 'admin' && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Edit />}
                      onClick={() => handleEdit(plan.id)}
                    >
                      {t('edit')}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<Delete />}
                      onClick={() => handleDelete(plan.id)}
                    >
                      {t('delete')}
                    </Button>
                  </>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {userRole === 'admin' && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
          <Button variant="contained" color="success" onClick={() => navigate('/plans/add')}>
            {t('addPlan')}
          </Button>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' }, 
          bgcolor: 'background.paper', 
          boxShadow: 24, 
          p: 4 
        }}>
          <IconButton 
            aria-label="close" 
            onClick={handleClose} 
            sx={{ 
              position: 'absolute', 
              top: 8, 
              right: 8, 
              color: (theme) => theme.palette.grey[500] 
            }}
          >
            <Close />
          </IconButton>
          <img 
            src={currentImage} 
            alt="Plan" 
            style={{ 
              width: '100%', 
              height: 'auto', 
              maxHeight: '80vh' 
            }} 
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Subscription;
