// src/components/MenuPDF.js
import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const MenuPDF = () => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      const docRef = doc(db, 'files', 'menu');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPdfUrl(docSnap.data().url);
      }
    };
    fetchPdfUrl();
  }, []);

  return (
    <div>
      {pdfUrl ? (
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#2ecc71',
            color: '#fff',
            textTransform: 'none',
            fontWeight: 600,
          }}
          startIcon={<CloudDownloadIcon />}
          href={pdfUrl}
          download="menu.pdf"
        >
          Download Menu
        </Button>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default MenuPDF;
