import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, doc, getDoc, updateDoc } from '../../config/firebase';
import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const EditExpense = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [expense, setExpense] = useState({
    title: '',
    item: '',
    date: '',
    business: '',
    amount: '',
    price: '',
    paymentMethod: ''
  });

  useEffect(() => {
    const fetchExpense = async () => {
      const expenseDoc = await getDoc(doc(db, 'expenses', id));
      if (expenseDoc.exists()) {
        setExpense(expenseDoc.data());
      }
    };
    fetchExpense();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExpense(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await updateDoc(doc(db, 'expenses', id), expense);
      navigate('/pos/expense-report');
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
      <TextField
        fullWidth
        label="Expense Title"
        variant="outlined"
        name="title"
        value={expense.title}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Expense Item"
        variant="outlined"
        name="item"
        value={expense.item}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Date"
        type="date"
        name="date"
        value={expense.date}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Supplier"
        variant="outlined"
        name="business"
        value={expense.business}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Amount"
        type="number"
        variant="outlined"
        name="amount"
        value={expense.amount}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Price"
        type="number"
        variant="outlined"
        name="price"
        value={expense.price}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel>Payment Method</InputLabel>
        <Select
          value={expense.paymentMethod}
          onChange={(e) => setExpense({ ...expense, paymentMethod: e.target.value })}
          label="Payment Method"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="cash">Cash</MenuItem>
          <MenuItem value="credit">Credit</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
        Save Changes
      </Button>
    </Box>
  );
};

export default EditExpense;
