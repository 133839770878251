import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  const { t } = useTranslation();

  const handleOrderNow = () => {
    window.location.href = "/menu";
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>DietCare - Healthy Food Delivery in Al Ain</title>
        <meta name="description" content="Order fresh, healthy, and delicious meals from DietCare, delivered hot to your doorstep in Alain, UAE. Perfect for anyone looking for nutritious food options." />
        <meta name="keywords" content="DietCare, healthy food delivery, Alain, UAE, fresh meals, nutritious food, diet-friendly" />
        <meta property="og:title" content="DietCare - Healthy Food Delivery in Alain, UAE" />
        <meta property="og:description" content="Craving something healthy? Order now from DietCare! We deliver fresh, healthy, and delicious meals right to your doorstep in Alain, UAE." />
        <meta property="og:image" content={BannerImage} />
        <meta property="og:url" content="https://www.dietcare.ae" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.dietcare.ae" />
      </Helmet>
      <Navbar />
      <header className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="DietCare healthy food delivery background" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            {t('Your Favourite Food Delivered Hot & Fresh in Alain, UAE')}
          </h1>
          <p className="primary-text">
            {t('Craving something healthy? Order now from DietCare! We deliver fresh, healthy, and delicious meals right to your doorstep in Alain, UAE.')}
          </p>
          <button className="secondary-button" onClick={handleOrderNow}>
            {t('Order Now')} <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="Fresh and healthy meals from DietCare" />
        </div>
      </header>
    </div>
  );
};

export default Home;
