import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../config/firebase';
import './EditElement.css';

const EditElement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [element, setElement] = useState({
    title: '',
    arTitle: '',
    description: '',
    arDescription: '',
    price: '',
    calories: '',
    protein: '',
    carbs: '',
    fiber: '',
    fat: '',
    images: [''],
    foodType: 'Null'  // New field for food type
  });
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchElement = async () => {
      const docRef = doc(db, 'elements', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setElement(docSnap.data());
      } else {
        console.error('No such document!');
      }
    };

    fetchElement();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setElement({ ...element, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (file) {
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const fileURL = await getDownloadURL(storageRef);
      element.images[0] = fileURL;
    }

    const docRef = doc(db, 'elements', id);
    await updateDoc(docRef, element);
    navigate(`/menu/${id}`);
  };

  return (
    <div className="edit-element-page">
      <form className="edit-element-form" onSubmit={handleSave}>
        <h1>Edit Element</h1>
        <label>
          Title:
          <input type="text" name="title" value={element.title} onChange={handleChange} />
        </label>
        <label>
          Arabic Title:
          <input type="text" name="arTitle" value={element.arTitle} onChange={handleChange} />
        </label>
        <label>
          Description:
          <textarea name="description" value={element.description} onChange={handleChange} />
        </label>
        <label>
          Arabic Description:
          <textarea name="arDescription" value={element.arDescription} onChange={handleChange} />
        </label>
        <label>
          Price:
          <input type="number" name="price" value={element.price} onChange={handleChange} />
        </label>
        <label>
          Calories:
          <input type="number" name="calories" value={element.calories} onChange={handleChange} />
        </label>
        <label>
          Protein:
          <input type="number" name="protein" value={element.protein} onChange={handleChange} />
        </label>
        <label>
          Carbs:
          <input type="number" name="carbs" value={element.carbs} onChange={handleChange} />
        </label>
        <label>
          Fiber:
          <input type="number" name="fiber" value={element.fiber} onChange={handleChange} />
        </label>
        <label>
          Fat:
          <input type="number" name="fat" value={element.fat} onChange={handleChange} />
        </label>
        <label>
          Food Type:
          <select
            name="foodType"
            value={element.foodType}
            onChange={handleChange}
            required
          >
            <option value="">Select food type</option>
            <option value="Meat">Meat</option>
            <option value="Chicken">Chicken</option>
            <option value="Fish">Fish</option>
          </select>
        </label>
        <label>
          Image URL:
          <input type="text" name="images" value={element.images[0]} onChange={(e) => setElement({ ...element })} />
        </label>
        <label>
          Image:
          <input type="file" onChange={handleFileChange} />
        </label>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}

export default EditElement;
