import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "Your Favourite Food Delivered Hot & Fresh": "Your Favourite Food Delivered Hot & Fresh",
          "Craving something healthy? Order now from Diet Care! We deliver fresh, healthy, and delicious meals right to your doorstep.": "Craving something healthy? Order now from Diet Care! We deliver fresh, healthy, and delicious meals right to your doorstep.",
          "Order Now": "Order Now",
          "Hide Diet Filters": "Hide Diet Filters",
          "Show Diet Filters": "Show Diet Filters",
          "Price Range (AED)": "Price Range (AED)",
          "Calories": "Calories",
          "Protein (g)": "Protein (g)",
          "Carbs (g)": "Carbs (g)",
          "Fiber (g)": "Fiber (g)",
          "Fat (g)": "Fat (g)",
          "Apply Diet": "Apply Diet",
          "Add to Cart": "Add to Cart",
          "foodSubscriptionPlans": "Food Subscription Plans",
          "chooseYourPlan": "Choose the best plan that suits your dietary needs and enjoy healthy meals delivered to your doorstep.",
          "monthlyPlan": "Monthly Plan",
          "totalProtein": "Total Protein",
          "totalCalories": "Total Calories",
          "totalCarbs": "Total Carbs",
          "totalFiber": "Total Fiber",
          "numberOfDays": "Number of Days",
          "subscribe": "Subscribe",
          "showMealList": "Show Meal List",
          "hideMealList": "Hide Meal List",
          "delete": "Delete",
          "addPlan": "Add Plan",
          "subscriptionMessage": "I'm interested in subscribing to the {{planName}} plan. Details: {{details}}",
          "numberOfMealsPerMonth": "Number of Meals per Month",
          "numberOfSnacksPerDay": "Number of Snacks per Day",
        }
      },
      ar: {
        translation: {
          "Your Favourite Food Delivered Hot & Fresh": "طعامك المفضل يُسلم ساخنًا وطازجًا",
          "Craving something healthy? Order now from Diet Care! We deliver fresh, healthy, and delicious meals right to your doorstep.": "هل تشتهي شيئًا صحيًا؟ اطلب الآن من دايت كير! نحن نوصل وجبات طازجة وصحية ولذيذة إلى باب بيتك.",
          "Order Now": "اطلب الآن",
          "Show Diet Filters": "عرض فلاتر النظام الغذائي",
          "Hide Diet Filters": "إخفاء فلاتر النظام الغذائي",
          "Price Range (AED)": "السعر (درهم)",
          "Calories": "السعرات الحرارية",
          "Protein (g)": "البروتين (جم)",
          "Carbs (g)": "الكربوهيدرات (جم)",
          "Fiber (g)": "الألياف (جم)",
          "Fat (g)": "الدهون (جم)",
          "Apply Diet": "تطبيق النظام الغذائي",
          "Add to Cart": "إضافة إلى السلة",
          "Order Details": "تفاصيل الطلب",
          "Price": "السعر",
          "Quantity": "الكمية",
          "Total": "الإجمالي",
          "Loading...": "جاري التحميل...",
          "Protein": "البروتين",
          "Carbs": "الكربوهيدرات",
          "Fiber": "الألياف",
          "Fat": "الدهون",
          "Order via WhatsApp": "الطلب عبر واتساب",
          "Edit": "تعديل",
          "Delete": "حذف",
          "Are you sure you want to delete this item?": "هل أنت متأكد من أنك تريد حذف هذا العنصر؟",
          "Logo": "الشعار",
          "Help": "المساعدة",
          "Share": "مشاركة",
          "Order": "اطلب",
          "Careers": "الوظائف",
          "Phone": "الهاتف",
          "Email": "البريد الإلكتروني",
          "Location": "الموقع",
          "Terms & Conditions": "الشروط والأحكام",
          "Privacy Policy": "سياسة الخصوصية",
          "foodSubscriptionPlans": "خطط الاشتراك الغذائي",
          "chooseYourPlan": "اختر أفضل خطة تناسب احتياجاتك الغذائية واستمتع بوجبات صحية يتم توصيلها إلى باب منزلك.",
          "monthlyPlan": "الخطة الشهرية",
          "totalProtein": "إجمالي البروتين",
          "totalCalories": "إجمالي السعرات الحرارية",
          "totalCarbs": "إجمالي الكربوهيدرات",
          "totalFiber": "إجمالي الألياف",
          "numberOfDays": "عدد الأيام",
          "subscribe": "اشترك",
          "showMealList": "عرض قائمة الوجبات",
          "hideMealList": "إخفاء قائمة الوجبات",
          "delete": "حذف",
          "addPlan": "إضافة خطة",
          "subscriptionMessage": "أنا مهتم بالاشتراك في خطة {{planName}}. التفاصيل: {{details}}",
          "Monday": "الاثنين",
          "Tuesday": "الثلاثاء",
          "Wednesday": "الأربعاء",
          "Thursday": "الخميس",
          "Friday": "الجمعة",
          "Saturday": "السبت",
          "Sunday": "الأحد",
          "numberOfMealsPerMonth": "عدد الوجبات في الشهر",
          "numberOfSnacksPerDay": "عدد الوجبات الخفيفة في اليوم",
          "Search": "بحث",
          "Download Menu": "تحميل القائمة",
          "Delivery Fee": "رسوم التوصيل",
          "Subtotal": "المجموع الفرعي",
          "Delivery Method": "طريقة التوصيل",
          "Pick Up": "استلام",
          "Delivery": "توصيل",
          "Payment Method": "طريقة الدفع",
          "Cash on Delivery": "الدفع عند الاستلام",
          "Credit Card": "الدفع بالبطاقة ",
          "Order Summary": "ملخص الطلب",
          "Confirm Order": "تأكيد الطلب",
          "AED": "درهم",
          "Checkout": "الدفع",
          "Orders with 80 AED or more get free delivery.": "الطلبات بقيمة 80 درهم أو أكثر تحصل على توصيل مجاني."

          
        }
      }
    },
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
