import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../config/firebase';
import './AddPlan.css';

const AddPlan = () => {
  const navigate = useNavigate();
  const [planName, setPlanName] = useState('');
  const[planPrice, setPlanPrice] = useState('');
  const [totalProtein, setTotalProtein] = useState('');
  const [totalCalories, setTotalCalories] = useState('');
  const [totalCarbs, setTotalCarbs] = useState('');
  const [totalFiber, setTotalFiber] = useState('');
  const [numberOfDays, setNumberOfDays] = useState('');
  const [numberOfMealsPerMonth, setNumberOfMealsPerMonth] = useState('');
  const [numberOfSnacksPerDay, setNumberOfSnacksPerDay] = useState('');
  const [meals, setMeals] = useState({
    Sunday: '',
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: ''
  });
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in meals) {
      setMeals({ ...meals, [name]: value });
    } else {
      switch (name) {
        case 'planName':
          setPlanName(value);
          break;
        case 'planPrice':
          setPlanPrice(value);
          break;
        case 'totalProtein':
          setTotalProtein(value);
          break;
        case 'totalCalories':
          setTotalCalories(value);
          break;
        case 'totalCarbs':
          setTotalCarbs(value);
          break;
        case 'totalFiber':
          setTotalFiber(value);
          break;
        case 'numberOfDays':
          setNumberOfDays(value);
          break;
        case 'numberOfMealsPerMonth':
          setNumberOfMealsPerMonth(value);
          break;
        case 'numberOfSnacksPerDay':
          setNumberOfSnacksPerDay(value);
          break;
        case 'image':
          setImage(e.target.files[0]);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageURL = '';
      if (image) {
        const storage = getStorage();
        const imageRef = ref(storage, `plans/${image.name}`);
        await uploadBytes(imageRef, image);
        imageURL = await getDownloadURL(imageRef);
        setImageURL(imageURL);
      }

      const docRef = await addDoc(collection(db, 'plans'), {
        planName,
        planPrice,
        totalProtein,
        totalCalories,
        totalCarbs,
        totalFiber,
        numberOfDays,
        numberOfMealsPerMonth,
        numberOfSnacksPerDay,
        meals,
        imageURL
      });
      console.log('Document written with ID: ', docRef.id);
      navigate('/');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <div className="add-plan">
      <h2>Add New Plan</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Plan Name:
          <input type="text" name="planName" value={planName} onChange={handleInputChange} required />
        </label>
        <label>
          Plan Price (AED):
          <input type="number" name="planPrice" value={planPrice} onChange={handleInputChange} required />
        </label>
        <label>
          Total Protein (g):
          <input type="number" name="totalProtein" value={totalProtein} onChange={handleInputChange}/>
        </label>
        <label>
          Total Calories (kcal):
          <input type="number" name="totalCalories" value={totalCalories} onChange={handleInputChange}/>
        </label>
        <label>
          Total Carbs (g):
          <input type="number" name="totalCarbs" value={totalCarbs} onChange={handleInputChange}/>
        </label>
        <label>
          Total Fiber (g):
          <input type="number" name="totalFiber" value={totalFiber} onChange={handleInputChange}/>
        </label>
        <label>
          Number of Days:
          <input type="number" name="numberOfDays" value={numberOfDays} onChange={handleInputChange} />
        </label>
        <label>
          Number of Meals per Month:
          <input type="number" name="numberOfMealsPerMonth" value={numberOfMealsPerMonth} onChange={handleInputChange} />
        </label>
        <label>
          Number of Snacks per Day:
          <input type="number" name="numberOfSnacksPerDay" value={numberOfSnacksPerDay} onChange={handleInputChange} />
        </label>
        <label>
          Plan Image:
          <input type="file" name="image" onChange={handleInputChange} />
        </label>
        <div className="meals">
          <h3>Meals</h3>
          {Object.keys(meals).map((day) => (
            <label key={day}>
              {day} Meal:
              <input
                type="text"
                name={day}
                value={meals[day]}
                onChange={handleInputChange}
              />
            </label>
          ))}
        </div>
        <button type="submit">Add Plan</button>
      </form>
    </div>
  );
};

export default AddPlan;
