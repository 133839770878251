import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { PersonAdd, Business, MoneyOff, AttachMoney, Calculate } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth

const PoSSidebar = () => {
  const { userRole } = useAuth(); // Get the userRole from context

  return (
    <List>
      <ListItem button component={Link} to="/pos/add-customer">
        <ListItemIcon>
          <PersonAdd />
        </ListItemIcon>
        <ListItemText primary="Add Customer" />
      </ListItem>
      <ListItem button component={Link} to="/pos/add-business">
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary="Add Supplier" />
      </ListItem>
      <ListItem button component={Link} to="/pos/add-item">
        <ListItemIcon>
          <AddCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Add Item" />
      </ListItem>
      <ListItem button component={Link} to="/pos/add-expense">
        <ListItemIcon>
          <MoneyOff />
        </ListItemIcon>
        <ListItemText primary="Add Expense" />
      </ListItem>
      <ListItem button component={Link} to="/pos/add-sale">
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary="Add Sale" />
      </ListItem>

      {/* Conditionally render the following items only if the userRole is 'admin' */}
      {userRole === 'admin' && (
        <>
          <ListItem button component={Link} to="/pos/calculate-cash">
            <ListItemIcon>
              <Calculate />
            </ListItemIcon>
            <ListItemText primary="Calculate Cash" />
          </ListItem>
          <ListItem button component={Link} to="/pos/sales-report">
            <ListItemIcon>
              <DataThresholdingIcon />
            </ListItemIcon>
            <ListItemText primary="Sales Report" />
          </ListItem>
          <ListItem button component={Link} to="/pos/expense-report">
            <ListItemIcon>
              <DataThresholdingIcon />
            </ListItemIcon>
            <ListItemText primary="Expense Report" />
          </ListItem>
        </>
      )}

      <ListItem button component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </List>
  );
};

export default PoSSidebar;
