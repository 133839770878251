import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Logo from "../Assets/logo.png";
import { FaInstagram, FaFacebookF, FaTiktok } from "react-icons/fa";
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <Link to="/">
            <img src={Logo} alt={t('Logo')} style={{ maxWidth: '4rem', height: 'auto' }} />
          </Link>
        </div>
        <div className="footer-icons">
          <a href="https://www.instagram.com/dietcarehf?igsh=bGYxeXpjaWs2d3Zi" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61560950775483&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.tiktok.com/@dietcarehealthfood?_t=8o81qCaFRqy&_r=1" target="_blank" rel="noopener noreferrer">
            <FaTiktok />
          </a>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <Link to="/help" className="footer-link">{t('Help')}</Link>
          <Link to="/share" className="footer-link">{t('Share')}</Link>
          <Link to="/order" className="footer-link">{t('Order')}</Link>
          <Link to="/careers" className="footer-link">{t('Careers')}</Link>
        </div>
        <div className="footer-section-columns">
          <a href="tel:+971556014603" className="footer-link">{t('Phone')}: +971 55 601 4603</a>
          <a href="mailto:contact@dietcare.ae" className="footer-link">{t('Email')}: contact@dietcare.ae</a>
          <a href="https://maps.app.goo.gl/PqvwuHtZiEHjxYGE6?g_st=com.google.maps.preview.copy" target="_blank" rel="noopener noreferrer" className="footer-link">{t('Location')}</a>
        </div>
        <div className="footer-section-columns">
          <Link to="/terms" className="footer-link">{t('Terms & Conditions')}</Link>
          <Link to="/privacy" className="footer-link">{t('Privacy Policy')}</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;