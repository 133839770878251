// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, getDoc, query, where, doc, setDoc, writeBatch, updateDoc, addDoc, deleteDoc, orderBy, limit, startAfter } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBqgxQ8uNiVi_ZDJf7vTlt_xY7KhLW0zig",
    authDomain: "diet-care-b2e29.firebaseapp.com",
    projectId: "diet-care-b2e29",
    storageBucket: "diet-care-b2e29.appspot.com",
    messagingSenderId: "235799057729",
    appId: "1:235799057729:web:bbd91f4343792eb4c6fdc0",
    measurementId: "G-YP0PMBJPTN"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();

export { app, analytics, auth, db, collection, getDocs, query, where, doc, setDoc, getDoc, writeBatch, updateDoc, addDoc, storage, deleteDoc, orderBy, limit, startAfter };
