import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db, collection, addDoc } from '../../config/firebase';
import PosSidebar from './PosSidebar';

const AddItem = () => {
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const navigate = useNavigate();

  const handleAddItem = async () => {
    if (!itemName) {
      alert("Item name is required");
      return;
    }

    try {
      await addDoc(collection(db, 'expenseItems'), {
        name: itemName,
        description: itemDescription,
      });
      console.log("New item added");
      navigate('/pos');
    } catch (e) {
      console.error("Error adding item: ", e);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <PosSidebar />
    
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Add New Expense Item
      </Typography>
      <TextField
        fullWidth
        label="Item Name"
        variant="outlined"
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Description"
        variant="outlined"
        value={itemDescription}
        onChange={(e) => setItemDescription(e.target.value)}
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" fullWidth onClick={handleAddItem}>
        Add Item
      </Button>
    </Box>
    </Box>
  );

};

export default AddItem;
