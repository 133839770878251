import React from 'react';
import { Box, Drawer, AppBar, Toolbar, Typography } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import PoSSidebar from './PosSidebar';
import AddCustomer from './AddCustomer';
import AddBusiness from './AddBusiness';
import AddExpense from './AddExpense';
import AddSale from './AddSale';
import CalculateCash from './CalculateCash';
import AddItem from './AddItem';

const drawerWidth = 240;

const PoS = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            PoS System
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <PoSSidebar />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, ml: drawerWidth }}
      >
        <Toolbar />
        <Routes>
          <Route path="/pos/add-customer" element={<AddCustomer />} />
          <Route path="/pos/add-business" element={<AddBusiness />} />
          <Route path="/pos/add-expense" element={<AddExpense />} />
          <Route path="/pos/add-sale" element={<AddSale />} />
          <Route path="/pos/calculate-cash" element={<CalculateCash />} />
          <Route path="/pos/add-item" element={<AddItem />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default PoS;
