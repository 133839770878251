import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './Filter.css';

const Filter = ({ onFilterChange }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    price: [0, 1000],
    calories: [0, 1000],
    protein: [0, 100],
    carbs: [0, 100],
    fiber: [0, 50],
    fat: [0, 100],
  });
  const [showFilter, setShowFilter] = useState(false);

  const handleSliderChange = (name, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: newValue,
    }));
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const applyFilters = () => {
    onFilterChange(filters);
    setShowFilter(false);
  };

  return (
    <div className="filter-wrapper">
      <button className="toggle-filter-button" onClick={toggleFilter}>
        {showFilter ? t('Hide Diet Filters') : t('Show Diet Filters')}
      </button>
      {showFilter && (
        <div className="filter-container">
          <FilterSlider
            name="price"
            label={t('Price Range (AED)')}
            min={0}
            max={1000}
            value={filters.price}
            onChange={(e, val) => handleSliderChange('price', val)}
          />
          <FilterSlider
            name="calories"
            label={t('Calories')}
            min={0}
            max={1000}
            value={filters.calories}
            onChange={(e, val) => handleSliderChange('calories', val)}
          />
          <FilterSlider
            name="protein"
            label={t('Protein (g)')}
            min={0}
            max={100}
            value={filters.protein}
            onChange={(e, val) => handleSliderChange('protein', val)}
          />
          <FilterSlider
            name="carbs"
            label={t('Carbs (g)')}
            min={0}
            max={100}
            value={filters.carbs}
            onChange={(e, val) => handleSliderChange('carbs', val)}
          />
          <FilterSlider
            name="fiber"
            label={t('Fiber (g)')}
            min={0}
            max={50}
            value={filters.fiber}
            onChange={(e, val) => handleSliderChange('fiber', val)}
          />
          <FilterSlider
            name="fat"
            label={t('Fat (g)')}
            min={0}
            max={100}
            value={filters.fat}
            onChange={(e, val) => handleSliderChange('fat', val)}
          />
          <button className="apply-filter-button" onClick={applyFilters}>
            {t('Apply Diet')}
          </button>
        </div>
      )}
    </div>
  );
};

const FilterSlider = ({ name, label, min, max, value, onChange }) => {
  return (
    <Box sx={{ width: 300, marginBottom: 2 }}>
      <Typography variant="body1" gutterBottom>
        {label}: {value[0]} - {value[1]}
      </Typography>
      <Slider
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        sx={{
          '& .MuiSlider-track': {
            bgcolor: '#2ecc71',
          },
          '& .MuiSlider-thumb': {
            bgcolor: '#2ecc71',
          },
        }}
      />
    </Box>
  );
};

export default Filter;