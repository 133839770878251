import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { userRole } = useAuth();

  if (!allowedRoles.includes(userRole)) {
    // If the user is not allowed, redirect to home or any other route
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
