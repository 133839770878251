// src/components/MainComponent.js
import React, { useState, useEffect } from 'react';
import List from './List';
import Filter from './Filter';
import MenuPDF from './MenuPDF';
import UploadPDF from './UploadPDF';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './MainComponent.css';

const MainComponent = () => {
  const [elements, setElements] = useState([]);
  const [filteredElements, setFilteredElements] = useState([]);
  const { userRole } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchElements = async () => {
      const querySnapshot = await getDocs(collection(db, 'elements'));
      const elementsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setElements(elementsData);
      setFilteredElements(elementsData);
    };
    fetchElements();
  }, []);

  const handleFilterChange = (filters) => {
    const filtered = elements.filter((element) => {
      return (
        (filters.price[0] === 0 || parseFloat(element.price) >= filters.price[0]) &&
        (filters.price[1] === 1000 || parseFloat(element.price) <= filters.price[1]) &&
        (filters.calories[0] === 0 || parseFloat(element.calories) >= filters.calories[0]) &&
        (filters.calories[1] === 1000 || parseFloat(element.calories) <= filters.calories[1]) &&
        (filters.protein[0] === 0 || parseFloat(element.protein) >= filters.protein[0]) &&
        (filters.protein[1] === 100 || parseFloat(element.protein) <= filters.protein[1]) &&
        (filters.carbs[0] === 0 || parseFloat(element.carbs) >= filters.carbs[0]) &&
        (filters.carbs[1] === 100 || parseFloat(element.carbs) <= filters.carbs[1]) &&
        (filters.fiber[0] === 0 || parseFloat(element.fiber) >= filters.fiber[0]) &&
        (filters.fiber[1] === 50 || parseFloat(element.fiber) <= filters.fiber[1]) &&
        (filters.fat[0] === 0 || parseFloat(element.fat) >= filters.fat[0]) &&
        (filters.fat[1] === 100 || parseFloat(element.fat) <= filters.fat[1])
      );
    });
    setFilteredElements(filtered);
  };

  return (
    <div>
      <Box className="filter-header" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Filter onFilterChange={handleFilterChange} />
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {userRole === 'admin' && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => navigate('/create')}
                sx={{ textTransform: 'none', fontWeight: 600 }}
              >
                +
              </Button>
              <UploadPDF />
            </>
          )}
          <MenuPDF />
        </Box>
      </Box>
      <List elements={filteredElements} />
    </div>
  );
};

export default MainComponent;
