import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { CartContext } from '../cart/CartProvider';
import { FaPlusCircle } from 'react-icons/fa';
import './List.css';

const List = ({ elements }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { addToCart } = useContext(CartContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const isArabic = i18n.language === 'ar';

  useEffect(() => {
    // Set dynamic meta tags based on content
    if (elements && elements.length > 0) {
      const firstElement = elements[0];
      const title = isArabic ? firstElement.arTitle : firstElement.title;
      const description = `${title} - ${firstElement.price} ${isArabic ? 'درهم' : 'AED'}`;

      // Update meta tags using Helmet
      document.title = `Menu | DietCare`;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.name = 'description';
        newMetaDescription.content = description;
        document.head.appendChild(newMetaDescription);
      }
    }
  }, [elements, isArabic]);

  const handleElementClick = (id) => {
    navigate(`/menu/${id}`);
  };

  const handleAddToCart = (element) => {
    addToCart({ ...element, quantity: 1 });
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentElements = elements
    .filter(element =>
      element.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      element.arTitle.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Helmet>
        <title>Menu | DietCare</title>
        <meta name="description" content="Explore our wide range of healthy meals available at DietCare. Order now for fresh and delicious food delivered to your doorstep." />
        <meta property="og:title" content="Menu - DietCare" />
        <meta property="og:description" content="Explore our wide range of healthy meals available at DietCare. Order now for fresh and delicious food delivered to your doorstep." />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://www.dietcare.ae/menu" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Menu - DietCare" />
        <meta name="twitter:description" content="Explore our wide range of healthy meals available at DietCare. Order now for fresh and delicious food delivered to your doorstep." />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      </Helmet>

      <div className="search-bar-container">
        <input
          type="text"
          placeholder={`🔍 ${t('Search')}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />      </div>

      <div className="list-container">
        {currentElements.map((element) => (
          <div key={element.id} className="list-item">
            <img
              src={element.images[0]}
              alt={isArabic ? element.arTitle : element.title}
              className="list-item-image"
              onClick={() => handleElementClick(element.id)}
            />
            <div className="list-item-details">
              <h3 className="list-item-title">
                {isArabic ? element.arTitle : element.title}
              </h3>
              <p className="list-item-price">
                {element.price} {isArabic ? 'درهم' : 'AED'}
              </p>
              <button className="add-to-cart-btn" onClick={() => handleAddToCart(element)}>
                <FaPlusCircle /> {t('Add to Cart')}
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination-container">
        <div className="pagination">
          {Array.from({ length: Math.ceil(elements.length / itemsPerPage) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={index + 1 === currentPage ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default List;
