import React, { useContext, useEffect, useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { CartContext } from './CartProvider';
import './Cart.css';

const CartIcon = () => {
  const { toggleCart, cartItems } = useContext(CartContext);
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    if (cartItems.length > 0) {
      setIsBlinking(true);
      const timer = setTimeout(() => setIsBlinking(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [cartItems]);

  return (
    <div className={`cart-icon ${isBlinking ? 'blink' : ''}`} onClick={toggleCart}>
      <FaShoppingCart />
      {cartItems.length > 0 && <div className="cart-count">{cartItems.length}</div>}
    </div>
  );
};

export default CartIcon;