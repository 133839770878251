import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Login from "./Components/auth/Login";
import Register from "./Components/auth/Register";
import Navbar from "./Components/Navbar";
import { useAuth, AuthProvider } from "./Components/contexts/AuthContext";
import CreateElement from "./Components/dashboard/CreateElement";
import List from "./Components/list/ListMainComponent";
import ElementPage from "./Components/element/Element";
import CartProvider from "./Components/cart/CartProvider";
import CartIcon from "./Components/cart/CartIcon";
import CartSidebar from "./Components/cart/CartSidebar";
import Checkout from "./Components/checkout/Checkout";
import EditElement from './Components/element/EditElement';
import Subscription from './Components/plans/Subscription';
import AddPlan from './Components/plans/AddPlan';
import Dashboard from './Components/dashboard/Dashboard';
import PoS from './Components/pos/PoS';
import AddCustomer from './Components/pos/AddCustomer';
import AddBusiness from './Components/pos/AddBusiness';
import AddExpense from './Components/pos/AddExpense';
import AddSale from './Components/pos/AddSale';
import CalculateCash from './Components/pos/CalculateCash';
import AddItem from './Components/pos/AddItem';
import SalesReport from './Components/pos/SalesReport';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import EditExpense from "./Components/pos/EditExpense";
import ExpenseReport from "./Components/pos/ExpenseReport";
import EditSale from "./Components/pos/EditSale";
import ProtectedRoute from "./Components/ProtectedRoute"; // Import the ProtectedRoute component
import EditPlan from "./Components/plans/EditPlan";
import "./App.css";

function App() {
  const { userRole } = useAuth(); // Ensure useAuth is within AuthProvider

  return (
    <I18nextProvider i18n={i18n}>
      <CartProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route
                path="/login"
                element={
                  <>
                    <Navbar />
                    <Login />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/register"
                element={
                  <>
                    <Navbar />
                    <Register />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/"
                element={
                  <>
                    <Home />
                    <About />
                    <Work />
                    <Testimonial />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/create"
                element={
                  <>
                    <Navbar />
                    <CreateElement />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/menu"
                element={
                  <>
                    <Navbar />
                    <List />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/menu/:id"
                element={
                  <>
                    <Navbar />
                    <ElementPage />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/checkout"
                element={
                  <>
                    <Navbar />
                    <Checkout />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/plans"
                element={
                  <>
                    <Navbar />
                    <Subscription />
                    <Footer />
                  </>
                }
              />
              <Route
                path="/plans/add"
                element={
                  <>
                    <Navbar />
                    <AddPlan />
                    <Footer />
                  </>
                }
              />
              <Route path="/edit/:id" element={<EditElement />} />
              <Route
                path="/contact"
                element={
                  <>
                    <Navbar />
                    <Contact />
                    <Footer />
                  </>
                }
              />

              <Route
                path="/dashboard"
                element={
                  <>
                    <Navbar />
                    <Dashboard />
                    <Footer />
                  </>
                }
              />
              {(userRole === 'admin' || userRole === 'mod') && (
                <>
                  <Route
                    path="/pos"
                    element={<PoS />}
                  />
                  <Route
                    path="/pos/add-customer"
                    element={
                      <>
                        <Navbar />
                        <AddCustomer />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/pos/add-business"
                    element={
                      <>
                        <Navbar />
                        <AddBusiness />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/pos/add-expense"
                    element={
                      <>
                        <Navbar />
                        <AddExpense />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/pos/add-sale"
                    element={
                      <>
                        <Navbar />
                        <AddSale />
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/pos/add-item"
                    element={
                      <>
                        <Navbar />
                        <AddItem />
                        <Footer />
                      </>
                    }
                  />
                </>
              )}

              {/* Admin-only routes */}
              <Route
                path="/pos/calculate-cash"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <>
                      <Navbar />
                      <CalculateCash />
                      <Footer />
                    </>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pos/sales-report"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <>
                      <Navbar />
                      <SalesReport />
                      <Footer />
                    </>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pos/expense-report"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <>
                      <Navbar />
                      <ExpenseReport />
                      <Footer />
                    </>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pos/edit-expense/:id"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <>
                      <Navbar />
                      <EditExpense />
                      <Footer />
                    </>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pos/edit-sale/:id"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <>
                      <Navbar />
                      <EditSale />
                      <Footer />
                    </>
                  </ProtectedRoute>
                }
              />
              <Route path="/pos*" element={<Navigate to="/" />} />
              <Route path="/plans/edit/:id" element={<EditPlan />} />
              <Route path="*" element={<Navigate to="/" />}
              />
            </Routes>

            <CartIcon />
            <CartSidebar />
          </div>
        </Router>
      </CartProvider>
    </I18nextProvider>
  );
}

export default App;
