import React, { useState } from "react";
import './Contact.css';
import { FaWhatsapp, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const Contact = () => {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState({
    phone: false,
    email: false,
    location: false
  });

  const toggleAccordion = (section) => {
    setIsOpen(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const handleSendWhatsApp = () => {
    const phoneNumber = "+971556014603"; 
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="contact-wrapper">
      <h2>Contact Us</h2>
      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-header" onClick={() => toggleAccordion('phone')}>
            <FaPhoneAlt /> Phone
          </div>
          {isOpen.phone && <div className="accordion-body">+971 55 601 4603</div>}
        </div>
        <div className="accordion-item">
          <div className="accordion-header" onClick={() => toggleAccordion('email')}>
            <FaEnvelope /> Email
          </div>
          {isOpen.email && <div className="accordion-body">contact@dietcare.ae</div>}
        </div>
        <div className="accordion-item">
          <div className="accordion-header" onClick={() => toggleAccordion('location')}>
            <FaMapMarkerAlt /> Location
          </div>
          {isOpen.location && (
            <div className="accordion-body">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2571.937274671235!2d55.77223615697172!3d24.267347997099616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8ab5168e1c9977%3A0xa1339e721c4081ec!2sDiet%20care!5e0!3m2!1sen!2sjo!4v1721423496568!5m2!1sen!2sjo" 
                width="600" 
                height="450" 
                style={{ border: 0 }} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map Location"
              ></iframe>
            </div>
          )}
        </div>
      </div>
      <div className="message-container">
        <textarea 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          placeholder="Type your message here..." 
        />
        <button onClick={handleSendWhatsApp} className="whatsapp-button">
          <FaWhatsapp /> Send via WhatsApp
        </button>
      </div>
    </div>
  );
};

export default Contact;
