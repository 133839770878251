import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { db } from '../../config/firebase';
import { FaWhatsapp, FaEdit, FaTrash } from 'react-icons/fa';
import { CartContext } from '../cart/CartProvider';
import { useAuth } from '../contexts/AuthContext';
import './ElementDetails.css';

const ElementPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [element, setElement] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useContext(CartContext);
  const { userRole } = useAuth();
  const restaurantWhatsAppNumber = '+971556014603';

  useEffect(() => {
    const fetchElement = async () => {
      const docRef = doc(db, 'elements', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setElement({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.error('No such document!');
      }
    };

    fetchElement();
  }, [id]);

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleOrder = () => {
    addToCart({ ...element, quantity: Number(quantity) });
  };

  const handleWhatsAppOrder = () => {
    const whatsappMessage = `${t('Order Details')}:\n\n${element.title}\n${t('Price')}: ${element.price} AED\n${t('Quantity')}: ${quantity}\n${t('Total')}: ${element.price * quantity} AED`;
    window.open(`https://wa.me/${restaurantWhatsAppNumber}?text=${encodeURIComponent(whatsappMessage)}`, '_blank');
  };

  const handleEdit = () => {
    navigate(`/edit/${element.id}`);
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(t('Are you sure you want to delete this item?'));
    if (confirmDelete) {
      await deleteDoc(doc(db, 'elements', id));
      navigate('/');
    }
  };

  if (!element) return <div>{t('Loading...')}</div>;

  const currentLanguage = i18n.language;
  const title = currentLanguage === 'ar' ? element.arTitle : element.title;
  const description = currentLanguage === 'ar' ? element.arDescription : element.description;

  return (
    <div className="element-page">
      <Helmet>
        <title>{`${title}`}</title>
        <meta name="description" content={`${description} in Al Ain`} />
        <meta property="og:title" content={`${title}`} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={element.images[0]} />
        <meta property="og:url" content={`https://www.dietcare.ae/menu/${id}`} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${title} - DietCare`} />
        <meta name="twitter:description" content={`${description} in Al Ain`}/>
        <meta name="twitter:image" content={element.images[0]} />
      </Helmet>
      <div className="element-content">
        <div className="element-image">
          <img src={element.images[0]} alt={title} />
        </div>
        <div className="element-details">
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="diet-details">
            {element.calories && (
              <p><strong>{t('Calories')}:</strong> {element.calories}</p>
            )}
            {element.protein && (
              <p><strong>{t('Protein')}:</strong> {element.protein} g</p>
            )}
            {element.carbs && (
              <p><strong>{t('Carbs')}:</strong> {element.carbs} g</p>
            )}
            {element.fiber && (
              <p><strong>{t('Fiber')}:</strong> {element.fiber} g</p>
            )}
            {element.fat && (
              <p><strong>{t('Fat')}:</strong> {element.fat} g</p>
            )}
          </div>
          <p className="price"><strong>{t('Price')}:</strong> {element.price} AED</p>
          <div className="quantity">
            <label htmlFor="quantity">{t('Quantity')}:</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              min="1"
              value={quantity}
              onChange={handleQuantityChange}
            />
          </div>
          <div className="buttons">
            <button className="order-button" onClick={handleOrder}>{t('Add to Cart')}</button>
            <button className="whatsapp-button" onClick={handleWhatsAppOrder}>
              <FaWhatsapp /> {t('Order via WhatsApp')}
            </button>
            {userRole === 'admin' && (
              <>
                <button className="edit-button" onClick={handleEdit}>
                  <FaEdit /> {t('Edit')}
                </button>
                <button className="delete-button" onClick={handleDelete}>
                  <FaTrash /> {t('Delete')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementPage;
