import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, collection, query, getDocs, deleteDoc, doc, orderBy, limit, startAfter, where } from '../../config/firebase';
import { 
  Box, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField, 
  MenuItem, 
  Typography, 
  Grid, 
  Container,
  IconButton
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

export default function ExpenseReport() {
  const [expenses, setExpenses] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [businessFilter, setBusinessFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [total, setTotal] = useState(0);
  const [businesses, setBusinesses] = useState([]);
  const [dateRangeTotal, setDateRangeTotal] = useState(0);

  const navigate = useNavigate();
  const pageSize = 10;

  const fetchExpenses = async (lastDoc = null) => {
    setLoading(true);
    try {
      let q = query(collection(db, 'expenses'));
  
      if (businessFilter) {
        q = query(q, where('business', '==', businessFilter));
      }
  
      if (startDate && endDate) {
        q = query(q, where('date', '>=', startDate), where('date', '<=', endDate));
      }
      
      q = query(q, orderBy('date', 'desc'));
  
      if (!startDate && !endDate) {
        if (lastDoc) {
          q = query(q, startAfter(lastDoc));
        }
        q = query(q, limit(pageSize));
      }
  
      const expenseSnapshot = await getDocs(q);
      const expensesData = expenseSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      if (!startDate && !endDate) {
        setExpenses(prev => lastDoc ? [...prev, ...expensesData] : expensesData);
        setLastVisible(expenseSnapshot.docs[expenseSnapshot.docs.length - 1]);
        setHasMore(expenseSnapshot.docs.length === pageSize);
      } else {
        setExpenses(expensesData);
        setHasMore(false);
      }
  
      const calculateTotal = (data) => {
        return data.reduce((sum, expense) => {
          const price = parseFloat(expense.price) || 0;
          return sum + price;
        }, 0);
      };

      if (businessFilter) {
        const businessTotal = calculateTotal(expensesData);
        setTotal(businessTotal);
      } else {
        setTotal(0);
      }

      const rangeTotal = calculateTotal(expensesData);
      setDateRangeTotal(rangeTotal);

    } catch (error) {
      console.error("Error fetching expenses: ", error);
      if (error.code === 'failed-precondition' || error.code === 'permission-denied') {
        alert('The query requires a custom index. Please visit the Firebase console to create the index.');
      }
    } finally {
      setLoading(false);
    }
  };
  
  const fetchBusinesses = async () => {
    try {
      const q = query(collection(db, 'expenses'));
      const expenseSnapshot = await getDocs(q);
      const businessSet = new Set(expenseSnapshot.docs.map(doc => doc.data().business));
      setBusinesses([...businessSet]);
    } catch (error) {
      console.error("Error fetching businesses: ", error);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [businessFilter, startDate, endDate]);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      fetchExpenses(lastVisible);
    }
  };

  const handleEdit = (id) => {
    navigate(`/pos/edit-expense/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'expenses', id));
      setExpenses(prev => prev.filter(expense => expense.id !== id));
      const remainingExpenses = expenses.filter(expense => expense.id !== id);
      const newTotal = remainingExpenses.reduce((sum, expense) => sum + parseFloat(expense.price || 0), 0);
      if (businessFilter) {
        setTotal(newTotal);
      }
      setDateRangeTotal(newTotal);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              label="Business"
              value={businessFilter}
              onChange={(e) => setBusinessFilter(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="">All Businesses</MenuItem>
              {businesses.map(business => (
                <MenuItem key={business} value={business}>{business}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Total expenses from {startDate || 'start'} to {endDate || 'end'}: {dateRangeTotal.toFixed(2)} AED
        </Typography>

        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Business</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenses.map(expense => (
                <TableRow key={expense.id}>
                  <TableCell>{expense.title}</TableCell>
                  <TableCell>{expense.date}</TableCell>
                  <TableCell>{expense.item}</TableCell>
                  <TableCell>{expense.business}</TableCell>
                  <TableCell>{expense.amount}</TableCell>
                  <TableCell>{parseFloat(expense.price).toFixed(2)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(expense.id)} color="primary" size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(expense.id)} color="secondary" size="small">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {businessFilter && (
                <TableRow>
                  <TableCell colSpan={5}><strong>Total for {businessFilter}</strong></TableCell>
                  <TableCell colSpan={2}><strong>{total.toFixed(2)}</strong></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {(!startDate && !endDate && hasMore) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleLoadMore} 
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Load More'}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}